<template>


      <div class="bg-white w-100% mb-7 p-3" style="border-radius: 5px; text-align: center" v-if="state.objTicket.tic_seq > 0">
        <b>TICKET #{{ state.objTicket.tic_seq }} du {{ formatDate(state.objTicket.tic_d_creation) }}</b>
        <b v-if="state.objTicket.tic_c_label"> - {{ state.objTicket.tic_c_label }}</b>
      </div>
      <div class="bg-white w-100% mb-7 p-3" style="border-radius: 5px" v-if="state.objTicket.tic_seq > 0">

        <el-steps :active="state.activeStep" simple finish-status="success">
          <el-step title="Validation" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'validation')]"/>
          <el-step title="Staging" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'staging')]" />
          <el-step title="Expédition" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'exped')]"/>
          <el-step title="Déploiement" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'deploy')]"/>

          <template v-for="(item, i) in state.objTicket.tic_c_serv.filter(element => element.xCheckUser == true && element.code.indexOf('custom') >=0 )" :key="i" >
            <el-step :title="byCode(item.code).name" />
          </template>

        </el-steps>
        
      </div>

      <div style="padding-left: 15px">

        <div v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'validation')]"
          style="display: flex; align-items: center; align-items: flex-start;" class="mb-10">

          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('validation') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">Validation du ticket</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-10" style="width: 100%" v-if="getStatusA('validation') == '-1'">
              <b>
                <el-alert title="Ce ticket nécessitera dans un premier temps une validation de Stillnetwork" class="h-60px bg-light-primary text-primary" type="info" description="" :closable="false"/>
              </b>
                <blockquote class="blockquote border-0 p-0 mx-7 mt-10">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-dark"></i><span>Votre interlocuteur Stillnetwork vérifiera la bonne cohérence de cette demande, aucune action ne sera déclenchée avant sa validation.</span><i class="fa fa-quote-right mx-3  mr-3 text-dark  "></i></p>
                </blockquote>
            </div>
            <div class="text-gray-900 mt-5 bg-white rounded p-10" style="width: 100%" v-if="getStatusA('validation') == 0">
              <b>
                <el-alert title="Ce ticket est en attente de validation" class="h-60px" type="warning" description="" :closable="false"/>
              </b>
                <blockquote class="blockquote border-0 p-0 mx-7 mt-10">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-warning"></i><span>Nos équipes ont reçu une notification et traiteront votre demande dans les plus brefs délais.</span><i class="fa fa-quote-right mx-3  mr-3 text-warning  "></i></p>
                </blockquote>
            </div>

            <div class="text-gray-900 mt-5 bg-white p-10" style="width: 100%" v-if="getStatusA('validation') == 1">
              <b><el-alert title="Ce ticket a été validé" class="h-60px" type="success" description="" :closable="false"/></b>
            </div>
            <div v-if="byCode('validation').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>Commentaires Stillnetwork :</b></div>
              <el-alert class="p-5" type="info" :title="byCode('validation').tis_notes" :closable="false"/>
            </div>

            <div v-if="returnFilesCode('validation')" class="mb-5 bg-white rounded px-10 pt-5 pb-10 mt-5 ">
              <div class='mt-7 mb-3'>Fichier(s) concernant la validation :</div>
              <template v-for="(item, index) in returnFilesCode('validation')" :key="index">
                <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                  <i class="las la-file-alt fs-2 me-2"></i>
                  Fichier : {{ item.name }}
                </a>
              </template>
            </div>

          </div>

          
        </div>


        <div v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'staging')]"
          style="display: flex; align-items: center; align-items: flex-start;" class="mb-10">

          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('staging') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>

          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">Staging</h3>
                       

            <el-container>
              <el-main class="p-0">

                <div v-if="byCode('staging').tis_notes" class="text-gray-900 bg-white p-10 mt-3" style="width: 100%" >
                  <div class="mb-2"><b>Commentaires Stillnetwork :</b></div>
                  <el-alert class="p-5" type="info" :title="byCode('staging').tis_notes" :closable="false"/>
                </div>

                <div class="text-gray-900 mt-5 bg-white rounded  p-10 mainStaging" :class="{'overflow-stagging': !state.stagingOnOff}" style="width: 100%;">
                  <b>Stager le  matériel avec les configurations suivantes :</b>
                  <ul class="mt-3 mb-10" v-for="(item, i) in state.objTicket.tic_staging" :key="i">           
                    <li>
                      <div class="text-gray-400"> <span class="w-150px  fs-6 text-dark">{{ item.loc_reference }}</span>  </div>
                      <div class="fs-7 text-gray-500 mb-2" style="display: -webkit-box;-webkit-line-clamp: 1; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;">{{ item.loc_designation }}</div>
                      <template v-for="(item, index) in item.fichiers" :key="index">
                          <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl" @click="downloadFile(item)" >
                            <i class="las la-file-alt fs-2 me-2"></i>
                            Fichier : {{ item.name }}
                          </a>
                      </template>

                      <blockquote class="blockquote border-0 p-0" v-if="item.commentaires">
                        <p class="font-italic lead fs-6"> <i class="fa fa-quote-left fs-8 text-primary" style="margin-right: 7px"></i><span v-html="item.commentaires.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span><i class="fa fa-quote-right fs-8 text-primary" style="margin-left: 7px"></i></p>
                      </blockquote>
                    </li>
                  </ul>
              </div>
              </el-main>
              <el-footer class="w-100%" style="height: 22px; background: #f9f9f9 !important; border: 1px solid #ebe8e8;cursor: pointer;" >
                <div style="text-align: center; line-height: 22px" class="fs-7 p-0" v-if="state.stagingOnOff==0"  @click="state.stagingOnOff=1">Afficher la liste complète...</div>
                <div style="text-align: center; line-height: 22px" class="fs-7 p-0" v-if="state.stagingOnOff==1" @click="state.stagingOnOff=0">Masquer une partie de la liste...</div>
              </el-footer>
            </el-container>

          </div>
        </div>


        <div v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'exped')]"
          style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('exped') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>

          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">Expédition</h3>

            <div class="text-gray-900 mt-5 bg-white rounded p-10" style="width: 100%">

              <b><el-alert title="Ce ticket a été expédié" class="h-60px mb-5" type="success" description="" :closable="false" v-if="!(getStatusA('exped') <= 0)" /></b>

              <b>Sortie du stock du matériel suivant :</b>
              <ul class="mt-3 mb-10" v-for="(item, i) in state.objTicket.tic_list_matos" :key="i">           
                <li>
                  <div class="text-gray-400"> <span class="w-150px  fs-6 text-dark">{{ item.loc_reference }}</span>  </div>
                  <div class="fs-7 text-gray-500 mb-2" style="display: -webkit-box;-webkit-line-clamp: 1; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;">{{ item.loc_designation }}</div>
                    <span class="me-5 fs-7 badge badge-light-warning  text-gray-800">
                        Quantité : {{ item.qte_exped }}
                    </span>

                    <span :class="{'badge-light-danger text-danger': item.qte_max - item.qte_exped == 0, 'badge-light-dark text-gray-800': item.qte_max - item.qte_exped > 0}" class="me-5 fs-7 badge " >
                      Prévision de stock : {{ item.qte_max - item.qte_exped }}
                    </span>
                </li>
              </ul>
              
              <div v-if="byCode('exped').type_exped == 0"><b>Expédition du matériel dés que possible à l'adresse :</b></div>
              <div v-if="byCode('exped').type_exped == 1"><b>Expédition du matériel à partir du {{ formatDate(byCode('exped').date1) }} à l'adresse :</b></div>
              <div v-if="byCode('exped').type_exped == 2" class="text-primary"><b>Blocage du matériel en vue d'une livraison future.</b></div>

              <ul class="mt-3" v-if="byCode('exped').type_exped != 2">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr('exped')" >
                      <span  v-if="byCodeAdr('exped')['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr('exped')['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr('exped')['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr('exped')['adresse'].adr_ligne_2 ">{{ byCodeAdr('exped')['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr('exped')['adresse'].adr_cp  }} - {{ byCodeAdr('exped')['adresse'].adr_ville  }} - {{ byCodeAdr('exped')['adresse'].adr_pays  }}</span>
                      <span v-if="byCodeAdr('exped')['adresse'].con_prenom "><br><br><b>Contact :</b> {{ byCodeAdr('exped')['adresse'].con_prenom  }} {{ byCodeAdr('exped')['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 

              <template v-if="byCode('exped').desc_globale">
                <div class='mt-7 mb-3'><b>Notes spécifiques à destination de l'équipe Stilnetwork gérant le stock :</b></div>
                <blockquote class="blockquote border-0 p-0 mx-7">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-primary"></i><span v-html="byCode('exped').desc_globale.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span></p>
                </blockquote>
              </template>

            </div>


            <div v-if="byCode('exped').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>Commentaires Stillnetwork :</b></div>
              <el-alert class="p-5" type="info" :title="byCode('exped').tis_notes" :closable="false"/>
            </div>

            <div v-if="returnFilesCode('exped')" class="mb-5 bg-white rounded px-10 pt-5 pb-10 mt-5 ">
              <div class='mt-7 mb-3'>Fichier(s) concernant l'expédition :</div>
              <template v-for="(item, index) in returnFilesCode('exped')" :key="index">
                <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                  <i class="las la-file-alt fs-2 me-2"></i>
                  Fichier : {{ item.name }}
                </a>
              </template>
            </div>

          </div>
        </div>

        <div class="mt-10" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'deploy')]"
            style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('deploy') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">Déploiement</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-5" style="width: 100%">

              <b><el-alert title="Ce ticket a été déployé" class="h-60px mt-2 mb-5" type="success" description="" :closable="false" v-if="!(getStatusA('deploy') <= 0)" /></b>

              <template v-if="byCode('deploy').desc_globale">
                <div class='mt-7 mb-3'>Description de votre besoin de déploiement :</div>
                <blockquote class="blockquote border-0 p-0 mx-7">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-primary"></i><span v-html="byCode('deploy').desc_globale.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span></p>
                </blockquote>
              </template>

              <div v-if="returnFilesCode('deploy')" class="mb-5">
                <div class='mt-7 mb-3'>Fichier(s) concernant le déploiement :</div>
                <template v-for="(item, index) in returnFilesCode('deploy')" :key="index">
                  <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                    <i class="las la-file-alt fs-2 me-2"></i>
                    Fichier : {{ item.name }}
                  </a>
                </template>
              </div>


              <b>Lieu du déploiement :</b>
              <ul class="mt-3">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr('deploy')" >
                      <span  v-if="byCodeAdr('deploy')['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr('deploy')['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr('deploy')['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr('deploy')['adresse'].adr_ligne_2 ">{{ byCodeAdr('deploy')['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr('deploy')['adresse'].adr_cp  }} - {{ byCodeAdr('deploy')['adresse'].adr_ville  }} - {{ byCodeAdr('deploy')['adresse'].adr_pays  }} </span>
                      <span v-if="byCodeAdr('deploy')['adresse'].con_prenom "><br><br><b>Contact :</b> {{ byCodeAdr('deploy')['adresse'].con_prenom  }} {{ byCodeAdr('deploy')['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 


            </div>

            <div v-if="byCode('deploy').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>Commentaires Stillnetwork :</b></div>
              <el-alert class="p-5" type="info" :title="byCode('deploy').tis_notes" :closable="false"/>
            </div>

          </div>
        </div>


        <div class="mt-10" v-for="(item, i) in state.objTicket.tic_c_serv.filter(element => element.xCheckUser == true && element.code.indexOf('custom') >=0 )" :key="i"  style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA(item.code) <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{ item.name }}</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-5" style="width: 100%">

              <template v-if="byCode(item.code).desc_globale">
                <div class='mt-7 mb-3'>Description de votre besoin :</div>
                <blockquote class="blockquote border-0 p-0 mx-7">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-primary"></i><span v-html="byCode(item.code).desc_globale.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span></p>
                </blockquote>
              </template>

              <div>
                <b>Prestation de service :</b>
                <ul class="mt-3">           
                    <div class="text-gray-400">
                      <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr(item.code)" >
                        <span v-if="item.date1" class='text-gray-800'>Première date d'intervention souhaitée le {{ formatDate(item.date1) }}.<br></span>
                        <span v-if="item.date2" class='text-gray-800'>Seconde date d'intervention souhaitée le {{ formatDate(item.date2) }}.<br></span>
                        <span v-if="item.date3" class='text-gray-800'>Troisième date d'intervention souhaitée le {{ formatDate(item.date3) }}.<br></span>
                        <span v-if="item.tis_date_planif" class='text-gray-800'><br><b>Intervention du technicien en date du {{ formatDate(item.tis_date_planif) }}.</b><br></span>
                        <span v-else class='text-gray-800'><br><b>Intervention du technicien en cours de planificiation.</b><br></span>
                        <span v-if="item.technicien_seq" class='text-gray-800'>Technicien effectuant la prestation : {{ item.technicien }}.<br></span>
                      </span>
                    </div>
                </ul> 
              </div>

              <div v-if="returnFilesCode(item.code)" class="mb-5">
                <div class='mt-7 mb-3'><b>Fichier(s) joint :</b></div>
                <template v-for="(item2, index) in returnFilesCode(item.code)" :key="index">
                  <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item2)" >
                    <i class="las la-file-alt fs-2 me-2"></i>
                    Fichier : {{ item2.name }}
                  </a>


                      <el-popconfirm
                        v-if="state.modeTic"
                        confirm-button-text="Oui, le supprimer !"
                        cancel-button-text="Non"
                        icon-color="primary"
                        title="Opération irréversible, voulez-vous supprimer ce fichier ?"
                        @confirm="delFile(item2.id,item.tis_seq)"
                      >
                        <template #reference>
                          <a  class="btn btn-sm btn-light-danger" style=" margin: 10px; width:150px"><i class="las la-trash fs-2 me-2"></i>Supprimer</a>
                        </template>
                      </el-popconfirm>

                  


                </template>
              </div>
              <GalerieImage :listImage="returnImgCode(item.code)" :modeTic="state.modeTic" :tic_seq="state.objTicket.tic_seq" :tis_seq="item.tis_seq"  @reloadTicket="reloadTicket($event)"/>

              <b>Adresse :</b>
              <ul class="mt-3">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr(item.code)" >
                      <span  v-if="byCodeAdr(item.code)['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr(item.code)['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr(item.code)['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr(item.code)['adresse'].adr_ligne_2 ">{{ byCodeAdr(item.code)['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr(item.code)['adresse'].adr_cp  }} - {{ byCodeAdr(item.code)['adresse'].adr_ville  }} - {{ byCodeAdr(item.code)['adresse'].adr_pays  }} </span>
                      <span v-if="byCodeAdr(item.code)['adresse'].con_prenom "><br><br><b>Contact :</b> {{ byCodeAdr(item.code)['adresse'].con_prenom  }} {{ byCodeAdr(item.code)['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 


            </div>

            <template v-if="!state.modeTic">
              <div v-if="byCode(item.code).tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
                <div class="mb-2"><b>Commentaires Stillnetwork :</b></div>
                <el-alert class="p-5" type="info" :title="byCode(item.code).tis_notes" :closable="false"/>
              </div>
            </template>
            <template v-else >
              <div  class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
                <div class="mb-2"><b>Modifier les commentaires Stillnetwork :</b></div>
                  <el-input
                  v-model="byCode(item.code).tis_notes"
                  :rows="4"
                  type="textarea"
                  placeholder="Saisir vos commentaires"
                  />
                  <a class="btn btn-sm btn-light-success me-2 mb-2 btn-text-dark mt-2" @click="updateCom(byCode(item.code).tis_seq, byCode(item.code).tis_notes) "
                    v-if="byCode(item.code).old_tis_notes != byCode(item.code).tis_notes"
                  >
                    <span class="svg-icon svg-icon-1 svg-icon-white"><inline-svg src="media/icons/duotune/general/gen043.svg"/> </span>
                    Appliquer
                  </a>
              </div>
            </template>

          </div>
        </div>


        <div class="mt-10" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'factu')]"
            style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('factu') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">Facturation</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-5" style="width: 100%">
              
              <b><el-alert title="Ce ticket a été facturé" class="h-60px mt-2 mb-5" type="success" description="" :closable="false" v-if="!(getStatusA('factu') <= 0)" /></b>

              <b>Adresse de facturation :</b>
              <ul class="mt-3">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr('factu')" >
                      <span  v-if="byCodeAdr('factu')['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr('factu')['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr('factu')['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr('factu')['adresse'].adr_ligne_2 ">{{ byCodeAdr('factu')['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr('factu')['adresse'].adr_cp  }} - {{ byCodeAdr('factu')['adresse'].adr_ville  }} - {{ byCodeAdr('factu')['adresse'].adr_pays  }} </span>
                      <span v-if="byCodeAdr('factu')['adresse'].con_prenom "><br><br><b>Contact :</b> {{ byCodeAdr('factu')['adresse'].con_prenom  }} {{ byCodeAdr('factu')['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 

              <div v-if="returnFilesCode('factu')" class="mb-5">
                <div class='mt-7 mb-3'>Fichier(s) joint :</div>
                <template v-for="(item, index) in returnFilesCode('factu')" :key="index">
                  <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                    <i class="las la-file-alt fs-2 me-2"></i>
                    Fichier : {{ item.name }}
                  </a>
                </template>
              </div>

            </div>

            <div v-if="byCode('factu').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>Commentaires Stillnetwork :</b></div>
              <el-alert class="p-5" type="info" :title="byCode('factu').tis_notes" :closable="false"/>
            </div>

          </div>
        </div>

      </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import * as moment from "moment";
import { ElNotification } from 'element-plus'

import GalerieImage from "@/views/still/deploy/galerieImage.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Affichage d'un ticket",
  emits: ["reloadTicket"],
  props: {
    objTicket : {
      default: {"tic_knum_societe": "", "tic_c_label": "", "tic_c_serv": [], "tic_list_matos": [],"tic_staging": []},
    },
    modeTic : {
      default: 0,
    },
  },
  components: {
    GalerieImage,
  },
  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      objTicket: props.objTicket as any,
      modeTic: props.modeTic as any,
      mAdresse: [] as any,
      activeStep : 0,
      stagingOnOff: 0,
    });
   
    onMounted(async () => {
      //console.log('OBJ', state.objTicket);
      let count = 0;
      state.objTicket.tic_c_serv.forEach(async (val) => { 

        val.old_tis_notes = val.tis_notes;

        if (val.knum_lieu && val.code) await returnAdresse(val.code, val.knum_lieu, val.knum_contact);
        count += 1;

        // if (val.tis_status != 'En cours') state.activeStep += 1;
        if (getStatusA(val.code) == 1 ) state.activeStep += 1;


        if( count == state.objTicket.tic_c_serv.length) state.loaderEnabled = false;
      });
      
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const byCode = (code) => {
      if (state.objTicket) return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)]
    }

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    const reloadTicket = () => {
      emit("reloadTicket", 1);
    };

    const delFile = (tif_seq,tis_seq) => {
      const form = {
       tif_knum_ticket: state.objTicket.tic_seq,
       tif_knum_ticket_services: tis_seq,
        tif_seq: tif_seq,
      }

      mAxiosApi
        .post("/delFileTicketService", form)
        .then( async() => {
            emit("reloadTicket", 1);
        })
    };

    const updateCom = (tis_seq, newCom) => {
      const form = {
       ticSeq: state.objTicket.tic_seq,
       tisSeq: tis_seq,
       newCom: newCom,
      }

      mAxiosApi
        .post("/updateComTicketService", form)
        .then( async() => {
          emit("reloadTicket", 1);
          ElNotification({
            title: 'Succès',
            message: 'Commentaires modifié',
            type: 'success',
          })

        })
    };
     

    const returnFilesCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.png') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.jpeg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.JPG') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.gif') <= 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const returnImgCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') > 0 || el.name.indexOf('.jpeg') > 0 || el.name.indexOf('.JPG') > 0 || el.name.indexOf('.png') > 0 || el.name.indexOf('.gif') > 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const byCodeAdr = (code) => {
      if (state.mAdresse) return state.mAdresse[state.mAdresse.findIndex(element => element.code == code)]
    }
    const returnAdresse = async (code, adr_seq, con_seq) => {
      let mAdresse = await getAxios("/getAdresseContact/" + adr_seq + '/' + con_seq);
      state.mAdresse.push({ code: code, adresse: mAdresse.records } )
    }

    const getStatus = (code) => {
      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
    }

    const getStatusA = (code) => {
      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return -1;
      const catStatus = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].cat_status;
      const arrayCat = JSON.parse(catStatus);
      //console.log(arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status).next);
      
      return arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status).next;
    }

    function b64toBlob(dataURI) {
        
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'text/plain' });
    }

    const downloadFile = async (item) => {
      
      console.log("En attente");
      let myList = await getAxios("/getFileTicket/" + item.id + '/' + item.name);
      console.log('File:',myList);
//      const myBlob = myList.results[0].tif_base64.substring(myList.results[0].tif_base64.indexOf('base64,')+7);

      // console.log(myList.results[0].tif_base64.blob());
      /*
      const data = encodeURI(myList.results[0].tif_base64);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", item.name);
      link.click();
      */
      
      const csvData = b64toBlob(myList.results[0].tif_base64);
      const csvUrl = URL.createObjectURL(csvData);
      const element = document.createElement("a");
      element.setAttribute("href",csvUrl);
      element.setAttribute("download", item.name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      console.log("En attente OK");

    };


    return {
      state,
      byCode,
      formatDate,
      returnAdresse,
      byCodeAdr,
      getStatus,
      getStatusA,
      downloadFile,
      returnFilesCode,
      returnImgCode,
      reloadTicket,
      delFile,
      updateCom
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>
